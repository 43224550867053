import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/home",
      name: "Home",
      component: () => import("../views/index/HomeIndex.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/login/LoginComponent.vue"),
    },
  ],
});
